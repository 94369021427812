@use 'variable' as var;

body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
  font-family: 'Noto Sans JP', '游ゴシック体', 'Yu Gothic', YuGothic, Meiryo,
    'メイリオ', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'ＭＳ Ｐゴシック', 'MS PGothic', Osaka, sans-serif;
  line-height: 1.6;
  color: var.$white;
  background-color: var.$colorblue01;
}

* {
  font-size: 1em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.05em;
}
header,
footer,
section,
article,
aside,
nav,
figure,
figcaption,
main {
  display: block;
}
ul,
ol,
dl,
li,
dt,
dd {
  list-style: none;
}
img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
}
*:focus {
  outline: none;
}
.font-en {
  font-family: 'Audiowide', 'Noto Sans JP', '游ゴシック体', 'Yu Gothic',
    YuGothic, Meiryo, 'メイリオ', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', Osaka,
    sans-serif;
}
a,
a:visited {
  color: var.$white;
  text-decoration: none;
  transition: ease 0.4s;
  &:hover {
    opacity: 0.6;
  }
}
//インナー
.inner {
  display: block;
  max-width: var.$inner-lg;
  margin: 0 auto;
  &.md {
    max-width: var.$inner-md;
  }
  &.sm {
    max-width: var.$inner-sm;
  }
  @include var.mq-down($bp: xl) {
    width: var.$inner-sp;
    .inner {
      width: 100%;
    }
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
//レイアウト
.pageArea {
  width: 100%;
  height: 100%;
}
.mainArea {
  position: relative;
  height: calc(100% - 50px);
  margin-top: 50px;
  &.withEpisodeSummary {
    height: calc(40% - 50px);
  }
}

//ヘッダー
.pageHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 50px;
  border-bottom: solid 1px var.$gray01;
  background-color: var.$black;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .logo {
    height: 24px;
    img {
      width: auto;
      height: 100%;
    }
  }
}

//episodeSummary
.episodeSummaryArea {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 60%;
  background-color: var.$colorblue01;
  .inner {
    height: 100%;
  }
  .infoArea {
    height: calc(100% - 80px);
    padding: 16px 0 0;
    .episodeInner {
      height: 100%;
      @include var.scrollbar;
    }
  }
  .btnArea {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 16px;
    & > * {
      width: calc((100% - 20px) / 2);
      @include var.btnNormal;
    }
  }
}

//episodeページ
.pageNav {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: var.$gray03;
  button {
    @include var.fontEn;
    font-size: 12px;
  }
}
.episodeDetailArea {
  padding-top: 16px;
}
.episodeInner {
  .heading {
    font-size: 12px;
    color: var.$colorRed01;
  }
}
.episodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .episodeInfo {
    width: calc(100% - 120px);
    & > * {
      padding: 0.25em 0;
      font-size: 14px;
    }
  }

  .category {
    .nameArea {
      font-size: 18px;
      .who {
        display: inline-block;
        margin-left: 0.5em;
        font-size: 12px;
        font-weight: bold;
        color: var.$colorRed01;
        span {
          margin: 0 0.2em;
          font-size: 16px;
        }
      }
    }
  }
  .address,
  .date {
    font-size: 12px;
  }
  .kaiiIcon {
    width: 100px;
  }
}
.episodeBody {
  padding-top: 0.25em;
  .txt {
    font-size: 12px;
    text-align: justify;
  }
}
.commentArea {
  padding: 16px 0;
  .heading {
    @include var.fontEn;
    color: var.$colorRed01;
  }
}
.commentInputArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    width: calc(100% - 60px);
    input {
      display: block;
      width: 100%;
      height: 35px;
      padding: 0 0.5em;
      font-size: 14px;
      background-color: transparent;
      border-bottom: solid 1px var.$whiteClear;
    }
  }
  .submit {
    width: 44px;
    button {
      display: block;
      width: 100%;
      font-size: 14px;
      color: var.$colorRed01;
    }
  }
}
.commentList {
  margin-top: 0.5em;
  & > * {
    padding: 0.5em 0;
    font-size: 14px;
    border-bottom: solid 1px var.$gray03;
    .date {
      font-size: 12px;
      color: var.$whiteClear;
    }
  }
}
.supportArea {
  position: fixed;
  top: calc(50px + 2%);
  left: 2%;
  z-index: 10;
  width: 96%;
}
.sortArea {
  .btnSort {
    width: 6em;
    padding: 0.5em;
    line-height: 1;
    border: solid 2px var.$colorRed01;
    border-image: linear-gradient(
      135deg,
      rgba(235, 117, 29, 1) 0%,
      rgba(169, 19, 96, 1) 100%
    );
    border-image-slice: 1;
    background: linear-gradient(
      135deg,
      rgba(235, 117, 29, 1) 0%,
      rgba(169, 19, 96, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .choiceArea {
    display: flex;
    justify-content: flex-start;
    justify-content: stretch;
    flex-wrap: wrap;
    width: 96%;
    margin-top: 10px;
    button {
      display: block;
      padding: 0.5em 0.75em 0.75em;
      margin-right: 0.75em;
      margin-bottom: 0.5em;
      font-size: 14px;
      line-height: 1;
      background: linear-gradient(
        135deg,
        rgba(235, 117, 29, 1) 0%,
        rgba(169, 19, 96, 1) 100%
      );
      border-radius: 8px;
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
    }
  }
}
.btnPost {
  position: absolute;
  bottom: calc(110px + 2%);
  right: 2%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: linear-gradient(
      180deg,
      rgba(235, 117, 29, 1) 0%,
      rgba(169, 19, 96, 1) 100%
    );
    border-radius: 100%;
    img {
      display: block;
      width: 66.7%;
    }
  }
}
.inputArea {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 4%;
  background-color: rgba(17, 22, 36, 0.8);
  .leadTxt {
    margin-bottom: 1em;
    font-size: 14px;
    text-align: center;
  }
  .address {
    margin: 1em 0;
    font-size: 14px;
    text-align: justify;
  }
  .confirm {
    padding-top: 1em;
    margin: 1em 0;
    font-size: 12px;
    text-align: center;
    border-top: solid 1px var.$gray02;
  }
  .textArea {
    textArea {
      width: 100%;
      height: 240px;
      padding: 0.5em;
      margin-bottom: 1em;
      resize: none;
      font-size: 16px;
      line-height: 1.5;
      color: var.$black;
    }
  }
  .mapArea {
    position: relative;
    width: 100%;
    height: 300px;
    .searchArea {
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      height: 36px;
      input {
        position: relative;
        z-index: 0;
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 1em;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 18px;
      }
      button {
        position: absolute;
        top: 50%;
        right: 10px;
        padding: 0.25em 0.75em;
        font-size: 12px;
        font-weight: bold;
        color: var.$white;
        transform: translateY(-50%);
        background: linear-gradient(
          180deg,
          rgba(235, 117, 29, 1) 0%,
          rgba(169, 19, 96, 1) 100%
        );
        border-radius: 12px;
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row-reverse;
    & > * {
      width: 49%;
      height: 40px;
      & + * {
        margin-right: 2%;
      }
    }
  }
  .btnNext {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      135deg,
      rgba(235, 117, 29, 1) 0%,
      rgba(169, 19, 96, 1) 100%
    );
  }
  .btnQuit {
    border: solid 2px var.$white;
    opacity: 0.6;
  }
}
.resultArea {
  .heading {
    margin-bottom: 1em;
    font-size: 20px;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(235, 117, 29, 1) 0%,
      rgba(169, 19, 96, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon {
    position: relative;
    width: 50%;
    margin: 0 auto 1em;
    &::before {
      content: '';
      display: block;
      width: 0;
      padding-top: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .result {
    text-align: center;
    .category {
      .name {
        font-size: 24px;
        font-weight: bold;
      }
      .sub {
        margin-left: 0.25em;
        font-size: 18px;
      }
    }
    .probability {
      background: linear-gradient(
        180deg,
        rgba(235, 117, 29, 1) 0%,
        rgba(169, 19, 96, 1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      .vol {
        font-size: 36px;
      }
      .sub {
        font-size: 20px;
      }
    }
  }
  .choice {
    padding-top: 0.5em;
    margin-top: 0.5em;
    border-top: solid 1px var.$gray02;
    .btnChoices {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75em 1em;
        margin-right: 1em;
        margin-bottom: 0.75em;
        font-size: 12px;
        font-weight: bold;
        color: var.$colorblue01;
        background-color: var.$white;
        &.selected {
          color: var.$white;
          background: linear-gradient(
            180deg,
            rgba(235, 117, 29, 1) 0%,
            rgba(169, 19, 96, 1) 100%
          );
        }
      }
    }
  }
  .btnArea {
    margin-top: 1em;
  }
}
