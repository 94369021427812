$black: #010101;
$white: #fefefe;
$whiteClear: rgba(255, 255, 255, 0.5);
$gray01: #1a1a1a;
$gray02: #808080;
$gray03: #333333;
$colorRed01: #c1272d;
$colorblue01: #111624;

$inner-lg: 1024px;
$inner-md: 920px;
$inner-sm: 720px;
$inner-sp: calc(100% - 32px);

$breakpoint-down: (
  'xs': 'screen and (max-width: 480px)',
  'sm': 'screen and (max-width: 768px)',
  'md': 'screen and (max-width: 960px)',
  'lg': 'screen and (max-width: 1024px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;

@mixin mq-down($bp: md) {
  @media #{map-get($breakpoint-down, $bp)} {
    @content;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Noto+Sans+JP:wght@400;700&display=swap');

@mixin fontEn {
  font-family: 'Audiowide', 'Noto Sans JP', '游ゴシック体', 'Yu Gothic',
    YuGothic, Meiryo, 'メイリオ', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', Osaka,
    sans-serif;
}

@mixin hoverBase($color: $black) {
  color: $color;
  text-decoration: none;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
    @include mq-down($bp: lg) {
      opacity: 1;
    }
  }
}
@mixin hoverReset() {
  transition: none;
  cursor: default;
  &:hover {
    opacity: 1;
  }
}

//スクロールバー
@mixin scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $colorblue01;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray03;
    border: solid 3px $colorblue01;
  }
}

@mixin btnNormal {
  padding: 0.5em;
  border: solid 2px $white;
}
